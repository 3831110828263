.inlineBlock {
  display: inline-block;
}

.displayBlock {
  display: block;
}

.paddingReset {
  padding: 0;
}
 
 h4 {
   line-height: 30px;
 }
 
.ratingViewStyle {
  background-image: url('../images/bg-sms-track.png');
  background-attachment: fixed;
  background-color: #274652;
  min-height: 100vh;
  font-family: 'Lato';
  text-align: center;
  overflow: hidden;
}
.ratingViewStyle .rateButton {
	color: white;
	border: solid white 1px;
	padding: 10px 0px;
	margin: 10px;
	width: 248px;
	background: #17323F;
	font-size: 12px;
	float: left;
}
.ratingViewStyle .otherButton {
	clear: both;
	color: white;
	border: solid white 1px;
	padding: 10px 0px;
	margin-top: 40px;
	background: #17323F;
	width: 520px;
	font-size: 12px;
	margin: 0 auto;
}

.yourFeedBack {
  color: white;
  font-size: 12px;
}

.ratingViewStyle .commentField {
    margin-top: 0px !important;
    width: 520px;
    padding: 10px 0px;
    background: #0097a9;
    border: none;
    height: 80px;
    color: white;
    font-weight: 400;
	  text-align: center;
    font-family: 'Lato';
}
.ratingViewStyle .selectedRateButton {
	color: white;
	background: #0097a9;
	padding: 10px 0px;
	margin: 10px;
	width: 248px;
	font-size: 12px;
	float: left;
	border: solid #0097a9 1px;
}
.star {
  margin: 10px;
}
.starAreaContainer {
  position: relative;
}
.starArea {
  position: relative;
  top: 55px;
}
.boxInfo{
  margin: 16px;
  width:68px;
}
h1 {
font-size: 25px;
margin: 0px 20px 20px 20px;
}

h4 {
    color: white;
    font-size: 17px;
    font-weight: 300;
    padding: 20px;
}

h1, h4 {
  color: white;
}
.ratingViewStyle .buttonArea {
  max-width: 540px;
  margin:0 auto;
  margin-top:20px;
}
.ratingViewStyle .comments {
	clear: both;
	padding-top: 10px;
	max-width: 540px;
    margin: 0 auto;
}
.ratingViewStyle .otherFieldArea {
	position: relative;
}
.ratingViewStyle ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: white;
}
.ratingViewStyle ::-moz-placeholder { /* Firefox 19+ */
  color: white;
}
.ratingViewStyle :-ms-input-placeholder { /* IE 10+ */
  color: white;
}
.ratingViewStyle :-moz-placeholder { /* Firefox 18- */
  color: white;
}
.ratingViewStyle .crossField {
	position: absolute;
	right: 30px;
	top: 13px;
}
.ratingViewStyle .infoLabels {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    float: left;
    width: 47%;
    text-align: left;
    padding-top: 140px;
    padding-left: 30px;
}
.ratingViewStyle .podGroup {
  float: right;
  width: 47%;
  position: relative;
  overflow: hidden;
}
.ratingViewStyle .pod {
  	position: absolute;
    left: 6.0vh;
    width: 30.1vh;
    height: 54.87vh;
    top: 32.2vh;
}
.ratingViewStyle .smallPod {
	display: none;
}

.ratingViewStyle .podMan {
  background-image: url('../images/man_right_edge.png');
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  z-index: 1000;
  position: relative;
  
}
.ratingViewStyle .mainTitleArea {
  color: white;
  font-size: 24px;
  font-weight: 200;
  max-width: 280px;
  margin-bottom: 20px;
  font-weight: normal;
}
.ratingViewStyle .titleArea {
  margin: 10px 0px;
  max-width: 550px;
}
  
.ratingViewStyle .titleLabel {
    color: #0097a9;
    font-size: 14px;
    margin-right: 16px;
    padding: 10px 0px;
}
.ratingViewStyle .descLabel {
    color: white;
    font-size: 14px;
    padding: 10px 0px;
}
.ratingViewStyle .introLabels{
	padding-top: 100px;
}

.ratingViewStyle .leftArrow {
	float: left;
    position: relative;
    top: 6px;
    left: -55px;
}

.ratingViewStyle .rightArrow {
	float: left;
    position: absolute;
    top: 6px;
    margin-left: 115px;
    box-shadow: none !important;
}


.ratingViewStyle .floatLeft {
	float: left;
	position: relative;
	top: -6px;
	
}

.ratingViewStyle .arrowAreas {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;
   margin-top: 30px;
   margin-bottom: 30px;
}

.ratingViewStyle .dotArea { 
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 80px;
}
.ratingViewStyle .dots {
	position: absolute;
	top: 80px;
}
.ratingViewStyle .activeDot {
	width: 6px;
	height: 6px;
	background: white;
	border-radius: 3px;
	margin:0 auto;
	float: left;
	margin-left: 5px;
    margin-right: 5px;
}
.ratingViewStyle .inactiveDot {
	width: 6px;
	height: 6px;
	background: gray;
	border-radius: 3px;
	margin:0 auto;
	float: left;
	margin-left: 5px;
    margin-right: 5px;
}
.clear {
  clear: both;
}

.characterCounter {
  font-size: 10px;
}

@media (min-aspect-ratio: 2/1) {
   .ratingViewStyle .pod {
	  position: absolute;
	  left: 3.2vw;
    	  width: 15.6vw;
      height: 28.54488vw;
      top: 16.5vw;
	}
	.ratingViewStyle .smallPod {
	  position: absolute;
	  left: 2.8vw; 
	  width:15.6vw;
	  height: 15.6vw*1.8298;
	  top: 11.5vw;
	}
}

@media screen and (max-width: 550px) {

 	.ratingViewStyle .logoArea {
	  text-align: left;
	  width: 105px;
	  position: absolute;
	  margin: 12px;
	}
	
	.ratingViewStyle .rateButton, .ratingViewStyle .otherButton {
	  float: none;
	  clear: both;
	  margin: 0 auto;
	  margin-bottom: 10px;
      width: 76%;
	}
	
	.ratingViewStyle .comments {
		padding-top: 0px;
	}
	
	.ratingViewStyle .commentField {
	  float: none;
	  clear: both;
	  margin: 0 auto;
    height: 130px;
	  margin-bottom: 10px;
      width: 76%;
      text-align: center;
	}
	 .ratingViewStyle .crossField {
		left: 80%;
	}
    .ratingViewStyle .ratingViewStyle, .ratingViewStyle .selectedRateButton {
	  float: none;
	  clear: both;
	  margin: 0 auto;
	  margin-bottom: 10px;
      width: 76%;
	}
	
	.ratingViewStyle .smallPodGroup {
		position: relative;
		top: 500px;
	}
	
	.ratingViewStyle .smallMan {
	  background-image: url('../images/man_only_hand.png');
	  width: 320px;
	  background-size: contain;
	  height: 460px;
	  background-repeat: no-repeat;
	  z-index: 1000;
      position: relative;
      margin:0 auto;
	}

	.ratingViewStyle .smallPod {
		position: absolute;
	   	width: 200px;
	    height: 380px;
	    top: 6vh;
	    display: block;
	    left: 50%;
	    margin-left: -113px;
	}
	
	.ratingViewStyle .podGroup {
		clear: both;
		display: none;
	}
	
	.ratingViewStyle .infoLabels {
		padding-top: 0px;
	    position: relative;
	    top: -450px;
	    margin: 0 auto;
	    width: 100%;
	}

  .yourFeedBack {
    display: none;
  }

  .characterCounter {
    margin-top: 0px;
  }
}




