.wrap {
  font-family: "Lato", Helvetica, sans-serif;
  override: scroll;
}

.parag {
  color: #3b3b3b;
  font-size: 16px;
  margin: 0;
  margin-bottom: 20px;
}

.parag div {
  margin-top: 5px;
  margin-bottom: 10px;
}

.parag div a {
  color: #0097a9;
  margin-left: 5px;
  font-weight: bold;
  font-size: 15px;
  text-decoration: underline;
}

.parag2 {
	font-size: 16px;
	color: #7c909a;
  font-weight: 700;
  line-height: 24px;
}

.paragTitle {
  font-weight: normal;
  color: #0c0c0c;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 10px;
}

h4 {
  color: #0097a9;
  margin-top: 30px;
  font-weight: bold;
  font-size: 21px;
  margin-bottom: 0;
  padding-bottom: 0;
}
