.wrap {
  font-family: "Lato", Helvetica, sans-serif;
  override: scroll;
}

.parag {
  color: #3b3b3b;
  line-height: 22px;
}
 
@media screen and (max-width: 450px) {

  h4 {
    font-size: 15px;
  }
  p {
   font-size: 12px;
  }
 
}
