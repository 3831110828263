
.wrapper {
  font-family: "Lato", Helvetica, sans-serif !important;
  background-attachment: fixed;
  min-height: 300px;
  font-family: 'Lato';
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #213c49;
} 
.wrapper .titleLabel {
    color: white;
    font-size: 17px;
    font-weight: 300;
    padding: 30px 20px 0px 20px;
    line-height: 22px;
} 

p {
 color: #90a4ae;
 margin-bottom: 20px;
 padding: 0px 20px;
}

.lateText {
	color: white;
	font-size: 15px;
	position: relative;
	top: -10px;
}

.subTitleLabel {
  position: relative;
  top: 15px;	
  font-size: 15px;
}

.shopTitle {
	font-size:17px;
	font-weight: bold;
    color: white;
    padding: 10px 20px 0px 20px;
}

.shopNumber {
	font-size:17px;
	font-weight: bold;
    color: white;
    padding: 5px 20px 30px 20px;
}

.wrapper .buttonArea {
  max-width: 540px;
  margin:0 auto;
  padding: 20px;
}

.wrapper .selectedRateButton {
	color: white;
	background: #0097a9;
	padding: 10px;
	font-size: 13px;
	margin-bottom: 10px;
	border: solid #0097a9 1px;
}

.wrapper .rateButton {
	color: white;
	border: solid white 1px;
	padding: 10px;
	margin-bottom: 10px;
	font-size: 13px;
}
.wrapper .submit {
  width: 150px; 
  margin: 30px; 
  height: 36px;
  line-height: 36px;
}

.wrapper .box {
 position: relative;
 left: 10px;
  margin-top: 60px;
}

.maxParag {
  max-width: 580px;
  padding: 0px 25px 70px 25px;
  margin: 0 auto;
  line-height: 20px;
}

.rescheduleParag {
  max-width: 550px;
  padding: 0px 40px 0px 40px;
  margin: 0 auto;
  line-height: 20px;
}

.transitTitle {
	margin-top: 20px;
	padding:20px;
	margin-bottom: 0;
	font-size: 17px;
	font-weight: bold;
	color: white;
}

.transitDesc {
	padding:0;
	margin:10px 0px 20px 0px;
	
}

@media screen and (max-width: 670px) {

  .wrapper {
	  display: block;
	  margin-top: 10px;
  }
   
  .wrapper .submit {
    width: 100%;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
