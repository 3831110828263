.fragileStyle {
  background-image: url('../images/bg-sms-track.png');
  background-attachment: fixed;
  background-color: #274652;
  min-height: 100vh;
  font-family: 'Lato';
  text-align: center;
  overflow: hidden;
}

.fragileStyle .introLabels {
	margin-top: 100px;
}

 h4 {
   line-height: 30px;
 }

.fragileStyle .titleLabel {
    color: white;
    font-size: 17px;
    font-weight: 300;
    padding: 30px;
}

.fragileStyle .leftArrow {
	float: left;
    position: relative;
    top: 6px;
    left: -55px;
}

.fragileStyle .rightArrow {
	float: left;
    position: absolute;
    top: 6px;
    margin-left: 115px;
    box-shadow: none !important;
}

.fragileStyle .rateButton {
	color: white;
	border: solid white 1px;
	padding: 10px 0px;
	margin: 10px;
	width: 248px;
	font-size: 12px;
	background: #17323F;
	float: left;
}
.fragileStyle .otherButton {
	clear: both;
	color: white;
	border: solid white 1px;
	padding: 10px 0px;
	margin-top: 40px;
	width: 520px;
	font-size: 12px;
	background: #17323F;
	margin: 0 auto;
}
.fragileStyle .commentField {
    width: 520px;
    background: #0097a9;
    border: none;
    height: 40px;
    color: white;
    font-weight: 400;
	text-align: center;
}
.fragileStyle .selectedRateButton {
	color: white;
	background: #0097a9;
	padding: 10px 0px;
	margin: 10px;
	width: 248px;
	font-size: 12px;
	float: left;
	border: solid #0097a9 1px;
}

.fragileStyle .buttonArea {
  max-width: 540px;
  margin:0 auto;
}
.fragileStyle .comments {
	clear: both;
	max-width: 540px;
    margin: 0 auto;
    text-align: center;
}
.fragileStyle .otherFieldArea {
	position: relative;
}
.fragileStyle ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: white;
}
.fragileStyle ::-moz-placeholder { /* Firefox 19+ */
  color: white;
}
.fragileStyle :-ms-input-placeholder { /* IE 10+ */
  color: white;
}
.fragileStyle :-moz-placeholder { /* Firefox 18- */
  color: white;
}
.fragileStyle .crossField {
	position: absolute;
	right: 30px;
	top: 13px;
}

.fragileStyle .floatLeft {
	float: left;
	position: relative;
	top: -6px;
	
}
.fragileStyle .arrowAreas {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;
   margin-top: 30px;
   margin-bottom: 30px;
}

.fragileStyle .dotArea {
   margin:0 auto;
   width: 225px;
   padding: 80px;
}
.fragileStyle .dots {
	position: absolute;
	top: 80px;
}
.fragileStyle .activeDot {
	width: 6px;
	height: 6px;
	background: white;
	border-radius: 3px;
	margin:0 auto;
	float: left;
	margin-left: 5px;
    margin-right: 5px;
}
.fragileStyle .inactiveDot {
	width: 6px;
	height: 6px;
	background: gray;
	border-radius: 3px;
	margin:0 auto;
	float: left;
	margin-left: 5px;
    margin-right: 5px;
}
.clear {
  clear: both;
}


@media screen and (max-width: 600px) {

	.logoArea {
	  text-align: left;
	  width: 105px;
	  position: absolute;
	  margin: 12px;
	}
	
	.fragileStyle .rateButton, .fragileStyle .otherButton {
	  float: none;
	  clear: both;
	  margin: 0 auto;
	  margin-bottom: 10px;
      width: 76%;
	}
	
	.fragileStyle .comments {
		padding-top: 0px;
	}
	
	.fragileStyle .commentField {
	  float: none;
	  clear: both;
	  margin: 0 auto;
	  margin-bottom: 10px;
      width: 76%;
      text-align: center;
	}
	.fragileStyle .crossField {
		left: 80%;
	}
    .fragileStyle .selectedRateButton {
	  float: none;
	  clear: both;
	  margin: 0 auto;
	  margin-bottom: 10px;
      width: 76%;
	}
	 
}