
.wrapper2 {
  font-family: "Lato", Helvetica, sans-serif !important;
  background-attachment: fixed;
  min-height: 100vh;
  font-family: 'Lato';
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.warningBar {
	background: #f68d2a;
	border-radius: 2px;
	font-size: 14px;
    padding: 10px;
    line-height: 20px;
    margin: 0px 15px;
	font-weight: 600;
}
h3 {
  font-weight: lighter;
}
.form {
  height: 400px;
  font-family: 'Lato';
  text-align: left;
  overflow: hidden;
  width: 450px;
  margin: 0 auto;
  position: relative;
  color: white;
}

.padding {
  padding-left: 15px;
  padding-right: 15px;
}

.margin {
  margin-left: 15px;
  margin-right: 15px;
}

textarea {
  background: #213c49;
  padding: 15px;
  margin-top: 10px;
  width: 95%;
  color: white;
  border-color: #213c49;
}

::-webkit-input-placeholder { /* Chrome */
  color: #778c97;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #778c97;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #778c97;
  opacity: 1;
}
:-moz-placeholder { /* Firefox 4 - 18 */
  color: #778c97;
  opacity: 1;
}

.rightAlign {
  text-align: right;
  font-size: 12px;
  color: #778c97;
  margin-right: 15px;
}

.noteButton {
    margin: 0 auto;
    position: relative;
    margin-top: 40px;
    width: 160px;
    display: block !important;
}
.subTitle {
    margin: 15px 0px 2px 0px;
    font-weight: lighter;
}
.power {
  text-align:right;
  margin-top: 22px;
  font-size: 12px;
  color: #778c97;
  right: 150px;
  position: fixed;
  bottom: 100px;
}
.sherpaLogo {
  height: 35px;
}

@media screen and (max-width: 1024px) {
 
  
  .power {
	top: 85px;
  }

}

@media screen and (max-width: 670px) {

  .wrapper {
	  display: block;
	  margin-top: 40px;
  } 

}

@media screen and (max-width: 450px) {

  .form {
	  width: 100%;
  }
  
  .button {
    width: 100%;
}
}
