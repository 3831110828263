.thanksStyle {
  background-image: url('../images/bg-sms-track.png');
  background-attachment: fixed;
  background-color: #274652;
  min-height: 100vh;
  font-family: 'Lato';
  text-align: center;
  overflow: hidden;
}
.thanksStyle .boxInfo{
  margin-top: 80px;
  width:68px;
}

 h4 {
   line-height: 30px;
 }
 
 .thanksStyle .titleLabel {
    color: white;
    font-size: 27px;
    font-weight: 300;
    padding: 30px 10px 0px 10px;
}
 .thanksStyle .descLabel {
    color: white;
    font-size: 17px;
    padding: 30px;
    font-weight:100;
}
