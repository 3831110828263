.page{
  background-image: url('../images/bg-sms-track.png');
  background-attachment: fixed;
  background-color: #274652;
}
.thanksStyle {
  min-height: 100vh;
  font-family: 'Lato';
  text-align: center;
  overflow: hidden;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  
  .google-review-image{
   max-width: 400px;
   width: 90%;
  }

  .descLabel {
    color: white;
    font-size: 17px;
    padding: 30px;
    font-weight:100;
    line-height: 27px;
    margin-top: 100px;
 }
 .red-button{
    width: 250px;
    color: #fff;
    padding: 10px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: bold;
    background: #c4105a;
    display: block;
    margin-top: 15px;
 }
  a:link, a:visited {
    text-decoration: none;
  }

}


