
.info-bar {
	background:#0097A9;
	border-radius: 2px;
	font-size: 14px;
  padding: 10px;
  line-height: 20px;
	font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.icon-container {
  margin-left: auto;
  padding-left: 10px;

  svg {
    cursor: pointer;
  }
}
.bannerArea {
  margin-top: 10px;
}