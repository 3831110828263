.App {
  text-align: center;
  font-family: Lato,Helvetica,sans-serif !important;
}
body {
    width:100%;
	height:calc(100vh);
	background-image:url('../images/error_background.png');
	font-family: Lato,Helvetica,sans-serif !important;
}
.logoArea {
  text-align: left;
  width: 105px;
  position: absolute;
  margin: 20px;
}
.orderGroup {
  text-align: center;
}
.shopLogo {
	width: auto;
	height: auto;
	float: left;
	background: white;
    border-radius: 10px;
    margin-right: 12px;
}
.sherpaLogo {
	height: auto;
}

.courierInfo {
	width: 100%;
	height: 60px;
	background: white;
}
.courierTitle {
    color: #0097a9;
    padding: 5px 0px 2px 0px;
    font-size: 10px;
    margin-top:10px;
}
.courierTitleGroup {
	float:left;
	color:black;
	text-align: left;
    color: #536e7b;
    font-size: 16px;
    margin-left: 15px;
}
.courierLogo {
	width: 40px;
	height: 40px;
	float:left;
	margin: 10px 15px 15px 15px;
	border-radius: 20px;
}
.etaGroup {
    color: white;
    font-size: 14px;
    position: absolute;
    right:0;
    margin:14px;
    top: 10px;
    background: #f58f00;
    border-radius: 2px;
}
.etaTitle {
    background: #ffa30a;
    padding: 10px;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}
.etaContents {
    background: #ff9900;
    padding: 10px 12px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.etaPosition {
    padding: 10px;
    margin-top: 8px;
}
.shopInfo {
	text-align: left;
}

.appHeader {
  height: 70px;
  padding: 14px;
  color: white;
  margin-top: 50px;
}

.appIntro {
  clear:both;
  font-size: large;
  position: relative;
  height: calc(100vh - 405px);
}

.infoView {
  background: #213c49;
}

.appFooter {
  width:100%;
  color: white;
  bottom:0px;
  margin-bottom: 50px;
}

.phone {
    background: #0097a9;
    float: right;
    width: 100px;
    height: 60px;
    overflow: hidden;
}
.phone img{
    position:relative;
    top:10px;
}

.shopTitle {
	font-size:12px;
	padding: 10px 0px 0px 0px;
	font-weight: bold;
}

.shopTitleContents {
	font-size:25px;
	font-weight: lighter;
}

.orderInfo {
    clear: both;
    font-size: 12px;
    padding: 10px;
    margin-top: 15px;
    text-align: center;
}

.errorBackground {
	width:100%;
	height:calc(100vh);
	background-image:url('../images/error_background.png');
}
.errorMessage {
	padding: 25px;
	color: white;
	padding-top:20%;
}

.orderContents {
	font-size: 20px;
	padding: 5px 15px 20px 15px;
	bottom: 10px;
    position: relative;
    padding-right: 100px;
}

.failedMessage {
	width: 250px;
	margin: 0 auto;
	padding: 20px 0px;
	text-align: left;
}

.paddingEdge {
  padding: 20px 40px;
}

.mapviewContainer {
  max-width: 1024px;
  margin: 0 auto;
}

.u-userLauncherColor:not([disabled]) {
 color: white !important;
}

.poweredByInfo {
	height: 50px;
}
.poweredBy {
    position: relative;
    padding: 10px;
    color: #778c97;
    font-size: 12px;
	top: -10px;
}


.order {
  text-align: center;
    width: 500px;
    margin: 0 auto;
    padding: 25px 25px 5px 25px;
    line-height: 20px;
}
.orderLabel {
  font-size: 10px;
}
.orderDescription {
  font-size: 18px;
  color: #ffffff;
  font-weight: normal;
}

.addNote {
  width: 160px;
  border-radius: 4px;
  transition: none;
  left: 0;
  position: absolute;
}
.power {
  text-align:right;
  margin-top: 22px;
  font-size: 12px;
  color: #778c97;
}

.addNote {
  top: 25px;
}

.statusBar {
  background: #0097a9;
  border-radius: 2px;
  font-size: 12px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  top: 0;
  left: 50%;
  margin-left: -130px;
  width: 260px;
  margin-top: 30px;
  position: absolute;
}

.crossField {
	position: absolute;
    right: 15px;
    top: 15px;
    width: 14px;
    height: 14px;
}

.covidBar {
 background: #0097a9;
  border-radius: 2px;
  font-size: 13px;
  padding-left: 13px;
  padding-right: 40px;
  padding-top: 13px;
  padding-bottom: 13px;
  color: white;
  margin: 20px;
  text-align: left;
  position: relative;
}

@media screen and (max-height: 460px) {

	.appFooter {
	  position: static;
	}


}

@media screen and (max-width: 460px) {

	.etaGroup {
	  font-size: 11px;
	}

    .etaPosition {
      width: auto;
    }
}



@media screen and (max-width: 1024px) {

	.appHeader {
	  margin-top: 0px;
	}

	.appFooter {
	  margin-bottom: 0px;
	}

	.order {
	 text-align: left;
	 width: auto;
	 left: 0;
	 padding-left: 18px;
	 margin-right: 100px;
	 min-height: 90px;
	 position: relative;
	 top: 0px;
	}
	.power {
  	  margin-right: 15px;
    }
	.addNote {
        top: 10px;
	    bottom: auto;
	    margin-left: 15px;
	    position: relative;
	    margin-bottom: 30px;
	}

	.orderGroup {
  		text-align: left;
	}
}
